@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300&family=Roboto&display=swap');

/* Global CSS Properties */


/* desktop */
@media only screen and (min-device-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) {
    #help-section-1{margin-right: 70px;}
    
  }
  
  /* ipad */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    
  }
  /* ipad pro */
  @media only screen and (min-device-width: 800px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    
  }
  /* mobile */
  @media only screen and (max-device-width: 767px) and (-webkit-min-device-pixel-ratio: 1) {
    .provi-service{
        margin-bottom: 50px;
    }
    #help-section-1{
        margin-top: 100px;
    }
    #help-section-app-store-links{
        justify-content: center;
    }
    .center-all{
        text-align: center;
        justify-content: center;
    }
    #contact-us-container{
        margin-top: 20px;
    }
    #email-container{
        display: flex;
        flex-direction: column;
        align-items: "center";
        justify-content: center;
        align-content:"center";
    }
  }
  